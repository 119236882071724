<template>
  <section class='section'>
    <modal-add
      v-if="modalAdd"
      :active="modalAdd"
      :close="showModal"
      :catalogCategoriesList="catalogCategoriesList"
      :saveCategory="saveCategory"
    />
    <modal-edit
      :close="showModalEdit"
      :catalogCategoriesList="catalogCategoriesList"
      :editCategory="editCategory"
      :editElement="editElement"
      v-if="modalEdit"
    />
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <h1>Categorías</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button
            class="button"
            type="button"
            :class="{ 'is-loading': isLoading }"
            @click="getCsvDownload"
          >
            Descargar
          </button>
        </div>
        <div class="level-item">
          <button @click="showModal" class="button button-givu">Agregar</button>
        </div>
      </div>
    </nav>
    <div class="columns is-multiline">
      <div class="column is-12">
        <base-table
          class="is-bordered is-striped is-narrow is-fullwidth is-hoverable bankingTable"
          :columns="columnsData"
          :data="categoriesList"
          :loading=loading
        >
        <div
          slot="config"
          slot-scope="scope"
        >
          <i class="material-icons icon-action" @click="showModalEdit(scope.row)">edit</i>
        </div>
        </base-table>
      </div>
    </div>
    <base-pagination
      class="is-medium"
      :page="page"
      :pages="parseInt(pages)"
      :action="paginator"
      :loading="loading"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex'

const DEFAULT_DISPLAY_SIZE = 10

export default {
  name: 'Categorias',
  components: {
    BaseTable: () => import('@/components/Table'),
    BasePagination: () => import('@/components/BasePagination'),
    modalAdd: () => import('@/components/Categories/ModalAdd'),
    modalEdit: () => import('@/components/Categories/ModalEdit')
  },
  data () {
    return {
      modalAdd: false,
      modalEdit: false,
      page: 1,
      pages: '',
      loading: false,
      isLoading: false,
      categoriesList: [],
      catalogCategoriesList: [],
      editElement: {},
      columnsData: [
        {
          id: 'id', header: '#', class: 'column-mobile'
        },
        { id: 'categoryFather', header: 'Categoría', class: 'column-mobile' },
        { id: 'name',
          header: 'Subcategoría',
          class: 'column-mobile'
        },
        { id: 'config', name: 'config', header: 'Acciones', class: 'column-mobile has-text-centered' }
      ]
    }
  },
  methods: {
    ...mapActions(['getCategories', 'getCatalogCategories', 'createCategory', 'updateCategory', 'csvDownloadCategories', 'addTemporalNotification']),
    paginator (page) {
      this.page = page
      this.loading = true
      this.getCategories({ page })
        .then(resp => {
          this.loading = false
          this.categoriesList = resp.payload
          this.pages = resp.totalPages
        })
    },
    showModal () {
      this.modalAdd = !this.modalAdd
    },
    showModalEdit (data) {
      this.modalEdit = !this.modalEdit
      this.editElement = data
    },
    saveCategory (data) {
      this.createCategory(data)
        .then(response => {
          if (response) {
            this.getCategories({ page: 1, size: DEFAULT_DISPLAY_SIZE })
              .then(resp => {
                this.categoriesList = resp.payload
                this.pages = resp.totalPages
              })
            this.showModal()
          }
        })
    },
    editCategory (data) {
      this.updateCategory(data)
        .then(response => {
          if (response) {
            this.getCategories({ page: 1, size: DEFAULT_DISPLAY_SIZE })
              .then(resp => {
                this.categoriesList = resp.payload
                this.pages = resp.totalPages
              })
            this.modalEdit = false
          }
        })
    },
    getCsvDownload () {
      this.isLoading = true
      this.csvDownloadCategories()
        .then(resp => {
          if (resp) {
            const exportName = 'Export categories.csv'
            const blob = new Blob([resp], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, exportName)
            } else {
              const link = document.createElement('a')
              if (link.download !== undefined) {
                const url = URL.createObjectURL(blob)
                link.setAttribute('href', url)
                link.setAttribute('download', exportName)
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
            }
          } else {
            this.addTemporalNotification({ type: 'danger', message: 'Error en la descarga' })
          }
          this.isLoading = false
        })
    }
  },
  beforeMount () {
    this.loading = true
    this.getCategories({ page: this.page, size: DEFAULT_DISPLAY_SIZE })
      .then(resp => {
        this.categoriesList = resp.payload
        this.pages = resp.totalPages
        this.loading = false
        this.getCatalogCategories()
          .then(list => {
            this.catalogCategoriesList = list
          })
      })
  }
}
</script>
<style scoped>
  .section {
    width: 100%;
    padding: 0 20px 0 20px;
  }
    .icon-action {
    cursor: pointer;
    color: #039DA1;
    font-size: 14px;
  }

  .icon-action:hover {
    color: var(--primary-color);
  }
</style>
